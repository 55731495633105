import React, {FunctionComponent, ReactNode} from "react"

type AWHeadingProps = {
    collapsible?: boolean,
    dividing?: boolean,
    priority?: number,
    renderActions?: () => ReactNode | null,
    renderLink?: () => ReactNode | null
}

const AWHeading: FunctionComponent<AWHeadingProps> = ({ children, collapsible = false, dividing = false, priority = 3, renderActions, renderLink }) => {
    const Tag = `h${priority}` as 'h3'
    return (
        <div className={`aw-heading${dividing ? ' dividing' : ''}`}>
            <Tag className="title">{children}</Tag>
            { renderLink && <div className="link">{renderLink()}</div> }
            { renderActions && <div className="actions">{ renderActions() }</div> }
        </div>
    )
}

export default AWHeading