import React, {Fragment, FunctionComponent, ReactNode, ReactNodeArray} from "react";
import AWIcon, {AWIconName} from "../icon";
import { isString } from '../../utils/string-utils';

interface AWControlGridProps {
    layout: 'GRID' | 'INLINE' | 'FULLSIZE',
    children: ReactNode | ReactNodeArray,
    [x: string]: any
}

// WRAPPER

const AWControlGrid: FunctionComponent<AWControlGridProps> = React.forwardRef(({ layout, children, className, ...otherProps }, ref) => {
    let gridClass
    switch(layout){
        case 'GRID':
            gridClass = 'grid'
            break
        case 'INLINE':
            gridClass = 'inline'
            break
        default:
            gridClass = 'fullsize'
    }
    return (
        <div ref={(ref as any) ?? undefined} className={`aw-control-grid ${gridClass}${className ? ' ' + className : ''}`} { ...otherProps }>
            {children}
        </div>
    );
});

export default AWControlGrid;

////////// SUBCOMPONENTS ///////////

type WrapperProps = {
    children?: ReactNode | ReactNodeArray,
    [x: string]: any
}

// ITEM

export const AWControlGridItem: FunctionComponent<WrapperProps & { highlighted?: boolean, disabled?: boolean, collapsed?: boolean, dragged?: boolean, available?: boolean, inError?: boolean, inWarning?: boolean}> = React.forwardRef(({ children, className, collapsed, disabled, highlighted, dragged, available, inError, inWarning, ...otherProps }, ref) => {
    return (
        <div ref={(ref as any) ?? undefined} 
            className={`aw-control-grid-item${highlighted ? ' highlighted' : (disabled ? ' disabled' : '')}${collapsed ? ' collapsed': ''}${available ? ' available': ''}${inError ? ' error': ''}${inWarning ? ' warning': ''}${className ? ' ' + className : ''}`}
            { ...otherProps}
        >
            {dragged && <div className="dragged"><AWIcon name="arrow_alt" /></div>}
            {children}
        </div>
    );
});

// HEADER

export const AWControlGridItemHeader: FunctionComponent<WrapperProps> = React.forwardRef(({ children, className, ...otherProps }, ref) => {
    return (
        <div ref={(ref as any) ?? undefined} className={ `header${className ? ' ' + className : ''}` } { ...otherProps}>
            {children}
        </div>
    )
});

export const AWControlGridItemStatus: FunctionComponent<WrapperProps> = React.forwardRef(({ children, className, ...otherProps }, ref) => {
    return (
        <div ref={(ref as any) ?? undefined} className={ `status${className ? ' ' + className : ''}` } { ...otherProps}>
            {children}
        </div>
    )
});


export const AWControlGridItemHeaderMetadata: FunctionComponent<WrapperProps &  { align?: 'right' | 'left' | 'center' }> = React.forwardRef(({ align, children, className, ...otherProps }, ref) => {
    return (
        <div ref={(ref as any) ?? undefined} className={ `metadata${align ? ' ' + align : ''}${className ? ' ' + className : ''}` } { ...otherProps}>
            {children}
        </div>
    )
});

export const AWControlGridItemHeaderControls: FunctionComponent<WrapperProps> = React.forwardRef(({ children, className, ...otherProps }, ref) => {
    return (
        <div ref={(ref as any) ?? undefined} className={ `controls${className ? ' ' + className : ''}` } { ...otherProps}>
            {children}
        </div>
    )
});

// CONTENT

export const AWControlGridItemContent: FunctionComponent<WrapperProps> = React.forwardRef(({ children, className, ...otherProps }, ref) => {
    return (
        <div ref={(ref as any) ?? undefined} className={ `content${className ? ' ' + className : ''}` } { ...otherProps}>
            {children}
        </div>
    )
});

export const AWControlGridItemContentMain: FunctionComponent<WrapperProps> = React.forwardRef(({ children, className, ...otherProps }, ref) => {
    return (
        <div ref={(ref as any) ?? undefined} className={ `main-content${className ? ' ' + className : ''}` } { ...otherProps}>
            {children}
        </div>
    )
});

export const AWControlGridItemContentMainCompact: FunctionComponent<WrapperProps> = React.forwardRef(({ children, className, ...otherProps }, ref) => {
    return (
        <Fragment>
            <div ref={(ref as any) ?? undefined} className={ `compact${className ? ' ' + className : ''}` } { ...otherProps}>
                {children}
            </div>
            <div className="vertical-line"></div>
        </Fragment>
    )
});

export const AWControlGridItemContentMainDetails: FunctionComponent<WrapperProps> = React.forwardRef(({ children, className, ...otherProps }, ref) => {
    return (
        <div ref={(ref as any) ?? undefined} className={ `details${className ? ' ' + className : ''}` } { ...otherProps}>
            {children}
        </div>
    )
});

export const AWControlGridItemContentAdditional: FunctionComponent<WrapperProps> = React.forwardRef(({ children, className, ...otherProps }, ref) => {
    return (
        <Fragment>
            <div className="horizontal-line"></div>
            <div ref={(ref as any) ?? undefined} className={ `additional-content pretty-scroll${className ? ' ' + className : ''}` } { ...otherProps}>
                {children}
            </div>
        </Fragment>
    )
});

export const AWControlGridItemThumbnail: FunctionComponent<WrapperProps & { overlay?: ReactNode, progress?: ReactNode, error?: string, info?: string, warning?: string,src?: string }> = React.forwardRef(({ children, className, overlay, progress, error, info, warning, src, ...otherProps }, ref) => {
    return (
        <div ref={(ref as any) ?? undefined} className={ `thumbnail${error || warning || info ? ' message' : ''}${className ? ' ' + className : ''}` } { ...otherProps}>
            { src && <img src={src}  alt="thumbnail"/> }
            { error && <div className="thumb-error">{error}</div>}
            { warning && <div className="thumb-warning">{warning}</div>}
            { info && <div className="thumb-info">{info}</div>}
            { overlay && <div className="overlay">{overlay}</div> }
            { progress && progress }
            { children }
        </div>
    )
});

export const AWControlGridItemContentTitle: FunctionComponent<WrapperProps> = React.forwardRef(({ children, className, ...otherProps }, ref) => {
    return (
        <div ref={(ref as any) ?? undefined} className={ `content-title${className ? ' ' + className : ''}` } { ...otherProps}>
            {children}
        </div>
    )
});

export const AWControlGridItemId: FunctionComponent<WrapperProps> = React.forwardRef(({ children, className, ...otherProps }, ref) => {
    return (
        <div ref={(ref as any) ?? undefined} className={ `id${className ? ' ' + className : ''}` } { ...otherProps}>
            {children}
        </div>
    )
});

export const AWControlGridItemPlaceholder: FunctionComponent<WrapperProps & { icon?: AWIconName | ReactNode, text?: string | ReactNode }> = React.forwardRef(({ children, className, icon, text, ...otherProps }, ref) => {
    return (
        <div ref={(ref as any) ?? undefined} className={ `placeholder${className ? ' ' + className : ''}` } { ...otherProps}>
            { icon && isString(icon) && <AWIcon name={icon as AWIconName}/> }
            { icon && !isString(icon) && icon }
            { text && <div>{text}</div> }
            {children}
        </div>
    )
});

export const AWControlGridItemSelect: FunctionComponent<WrapperProps> = React.forwardRef(({ children, className, ...otherProps }, ref) => {
    return (
        <div ref={(ref as any) ?? undefined} className={ `select${className ? ' ' + className : ''}` } { ...otherProps}>
            {children}
        </div>
    )
});

export const AWControlGridItemActions: FunctionComponent<WrapperProps> = React.forwardRef(({ children, className, ...otherProps }, ref) => {
    return (
        <div ref={(ref as any) ?? undefined} className={ `actions${className ? ' ' + className : ''}` } { ...otherProps}>
            {children}
        </div>
    )
});