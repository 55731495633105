import { Field, FieldProps } from "formik";
import React, { Fragment, FunctionComponent } from "react";
import { FormFeedback, FormGroup, Label, UncontrolledTooltip } from 'reactstrap';
import { AWFormFieldCommonProps, AWFormFieldErrorName } from './forms.types';
import { getErrorText, getText } from './forms.utils';
import AWIcon from '../icon';
import AWDateInput from '../date-input';

interface AWFormFieldDateProps extends AWFormFieldCommonProps {
  format?: string;
  locale?: string;
  autoComplete?: string | undefined;
  minDate?: string | Date;
  maxDate?: string | Date;
}

const AWFormFieldDate: FunctionComponent<AWFormFieldDateProps> = ({
                                                                        componentProps = {},
                                                                        errorTexts,
                                                                        fieldTexts,
                                                                        hintPosition = 'bottom',
                                                                        wrapperProps = {},
                                                                        autoComplete,
                                                                        format,
                                                                        locale,
                                                                        minDate,
                                                                        maxDate,
                                                                        ...fieldProps
                                                                      }) => {
  const { value: componentValue, type, ...otherComponentProps } = componentProps;

  // Texts
  const label = getText(fieldTexts, fieldProps.name, 'label');
  const hint = getText(fieldTexts, fieldProps.name, 'hint');

  // Computing id
  if(!otherComponentProps.id){
    otherComponentProps.id = fieldProps.name;
  }

  return (
    <Field
      {...fieldProps}>
      {(props: FieldProps) => {
        const { field, meta, form } = props;
        const { value, ...otherFormikFieldProps } = field;

        return (
          <FormGroup { ...wrapperProps }>
            {label &&
            <Label for={otherComponentProps.id}>
              {label}
            </Label>
            }
            <AWDateInput onChange={(date, formatted) => form.setFieldValue(field.name, formatted)}
                         inputProps={{...otherFormikFieldProps, disabled: fieldProps.disabled, autoComplete, invalid: meta.error !== null && meta.error !== undefined}}
                         value={value}
                         format={format}
                         locale={locale}
                         minDate={minDate}
                         maxDate={maxDate} />
            { hint && hintPosition === 'icon' &&
            <Fragment>
                <AWIcon className="form-hint"
                        id={`${otherComponentProps.id}_hint`}
                        name="info_circle"/>
                <UncontrolledTooltip aria-describedby={`${otherComponentProps.id}_hint`}
                                     placement="top"
                                     target={`${otherComponentProps.id}_hint`}
                                     trigger="hover">
                  {() => (
                    <div>{ hint }</div>
                  )}
                </UncontrolledTooltip>
            </Fragment>
            }
            <FormFeedback>
              { getErrorText(errorTexts, meta.error as AWFormFieldErrorName) }
            </FormFeedback>
            { hint && hintPosition === 'bottom' &&
            <div className="indicator">
              { hint }
            </div>
            }
          </FormGroup>
        );
      }}
    </Field>
  );
}

export default AWFormFieldDate;