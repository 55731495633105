import {Placement} from "@popperjs/core"
import React, { FunctionComponent, ReactNode, ReactNodeArray, Ref, useCallback, useEffect, useState } from 'react';
import ReactDOM from "react-dom";
import {Manager, Popper, Reference} from "react-popper";
import onClickOutside from "react-onclickoutside";

import {isString} from "../../utils/string-utils";
import {useKeyPress} from "../../hooks/use-key-press"

type AWChildrenRenderPropsType = {
    close: () => void;
}

type AWTriggerRenderPropsType = {
    ref: Ref<any>;
    onClick: (e: Event) => void;
    className: string;
    opened: boolean;
}

interface AWPopupProps {
    arrow?: boolean;
    shadow?: boolean;
    children: (params: AWChildrenRenderPropsType) => ReactNode;
    direction?: Placement;
    header?: string | ReactNode | Element;
    onClose?: () => void;
    onOpen?: () => void;
    portal?: boolean;
    renderTrigger: (params: AWTriggerRenderPropsType) => ReactNode;
    scrollable?: boolean;
    [x: string]: any;
}

const AWPopup: FunctionComponent<AWPopupProps> = ({
                                                      arrow = false,
                                                      children,
                                                      direction = 'bottom',
                                                      header,
                                                      onClose,
                                                      onOpen,
                                                      portal = false,
                                                      renderTrigger,
                                                      scrollable = false,
                                                      shadow = false,
                                                      className, 
                                                      ...otherProps
}) => {

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if(onOpen && isOpen){
            onOpen();
        }
        else if(onClose && !isOpen){
            onClose();
        }
      }, [isOpen, onOpen, onClose]);

    const toggle = (e: Event) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
    }

    const closePopup = useCallback(() => {
        if(isOpen){
            setIsOpen(false);
        }
    }, [isOpen, setIsOpen]);

    useKeyPress({
        targetKeys: ['Esc', 'Escape', 27],
        handler: closePopup
    });

    const EnhancedPopper = onClickOutside(Popper, {
        handleClickOutside: () => closePopup
    });
    let modifiers:any = undefined;
    if(arrow){
        modifiers = [
            {
                name: 'arrow',
                options: {
                    padding: 5 //Based on popper border radius
                }
            },
            {
                name: 'offset',
                options: {
                    offset: [0, 5] //Based on arrow size
                }
            }
        ];
    }

    const popper = (
        <EnhancedPopper placement={direction}
                        modifiers={modifiers}>
            {({ref, style, placement, arrowProps, isReferenceHidden }) => (
                <div ref={ref} className={`aw-popup${isReferenceHidden ? ' hidden-reference' : ''}${shadow ? ' with-shadow': ''}${className ? ' ' + className : ''}`} style={style} {...otherProps}>
                    <div className={`aw-popup-content ${scrollable ? 'scrollable pretty-scroll' : ''}`}>
                        { isString(header) ? <AWPopupHeader>{ header }</AWPopupHeader> : header }
                        { children({ close: closePopup }) }
                    </div>
                    { arrow && <div data-popper-arrow className="arrow" data-placement={placement} ref={arrowProps.ref} style={arrowProps.style}/> }
                </div>
            )}
        </EnhancedPopper>
    );
    return (
        <Manager>
            <Reference>
                {({ ref }) => renderTrigger({ref, onClick: toggle, className: isOpen ? 'ignore-react-onclickoutside' : '', opened: isOpen })}
            </Reference>
            { isOpen && !portal && popper }
            { isOpen && portal && ReactDOM.createPortal(popper, document.body)}
        </Manager>
    );
}

type WrapperProps = {
    children?: ReactNode | ReactNodeArray,
    [x: string]: any
}

export const AWPopupHeader: FunctionComponent<WrapperProps & { secondary?: boolean }> = ({ children, className, secondary, ...otherProps }) => {
    return (
        <div className={`aw-popup-header${secondary ? ' secondary' : ''}${className ? ' ' + className : ''}`} {...otherProps}>
            {children}
        </div>
    )
}

export const AWPopupRow: FunctionComponent<WrapperProps> = ({children, className, ...otherProps}) => {
    return (
        <div className={`aw-popup-row${className ? ' ' + className : ''}`} {...otherProps}>
            {children}
        </div>
    )
}

export const AWPopupCol: FunctionComponent<WrapperProps> = ({children, className, ...otherProps}) => {
    return (
        <div className={`aw-popup-col${className ? ' ' + className : ''}`} {...otherProps}>
            {children}
        </div>
    )
}

export default AWPopup;