import React, {
    Fragment,
    FunctionComponent,
    ReactEventHandler,
    ReactNode,
    useState
} from 'react';
import {
    Badge,
    Button,
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavbarBrand,
    NavItem,
    NavLink,
    UncontrolledDropdown
} from 'reactstrap';

import AWIcon, {AWIconName} from "../icon";
import {isString} from "../../utils/string-utils";
import AWAviwestLogo from "../logo/aviwest";

interface AWMenuItem {
    active?: boolean,
    as?: string | ReactNode | any,
    badge?: number | string | ReactNode,
    badgeColor?: string,
    icon?: AWIconName | ReactNode,
    title: string | ReactNode,
    [x: string]: any
}

interface AWNavBarProps {
    beta?: boolean,
    title?: string,
    collapsible?: boolean,
    logo?: ReactNode,
    leftInfo?: ReactNode,
    onBurgerMenuClick?: () => void,
    onLogoClick?: () => void,
    tabs?: Array<AWMenuItem>,
    rightNav?: ReactNode;
    rightTrigger?: ReactNode;
    rightMenu?: Array<Array<AWMenuItem>> // Double entries array, used as sections
}

const AWNavBar: FunctionComponent<AWNavBarProps> = (props) => {
    const {
        beta,
        title,
        collapsible,
        logo,
        leftInfo,
        onBurgerMenuClick,
        onLogoClick,
        tabs,
        rightTrigger,
        rightMenu,
        rightNav
    } = props;

    const [collapsed, setCollapsed] = useState(true);

    const toggleMenu = () => {
        setCollapsed(!collapsed);
    }

    return (
        <Navbar className="aw-navbar"
                dark
                expand="md"
                fixed="top">
            { !collapsible && onBurgerMenuClick &&
            <Button className="basic burger-menu"
                    onClick={onBurgerMenuClick as ReactEventHandler}>
                <AWIcon name="burger_menu"/>
            </Button>
            }
            { collapsible &&
            <Button className="basic burger-menu"
                    onClick={toggleMenu}>
                <AWIcon name="burger_menu"/>
            </Button>
            }
            <NavbarBrand tag={onLogoClick ? 'a' : 'span'} onClick={onLogoClick as ReactEventHandler}>
                {logo ?? <AWAviwestLogo width={120} inverted={true}/>}
            </NavbarBrand>
            { title && <span className={`navbar-title ${beta ? '' : 'mr-3'}`}>{title}</span>}
            { beta && <small className="pb-3 mr-3">BETA UI</small> }
            { leftInfo && leftInfo }
            { tabs != null &&
            <Collapse isOpen={!collapsed}
                      navbar>
                <Nav className="aw-menu"
                     navbar>
                    { tabs.map(({active, as, badge, badgeColor, icon, title, ...otherProps}, index) => (
                        <NavItem key={index}>
                            <NavLink tag={as as any} active={active} {...otherProps}>
                                { icon != null && isString(icon) && <AWIcon name={icon as AWIconName}/> }
                                { icon != null && !isString(icon) && icon }
                                <span className="title">{title}</span>
                                { badge && <Badge color={badgeColor ? badgeColor : 'warning'}>{badge}</Badge>}
                            </NavLink>
                        </NavItem>
                    ))}
                    { collapsible && rightMenu &&
                    <Fragment>
                        <div className="right-menu-trigger at-most-medium">
                            {rightTrigger}
                        </div>
                        {/*<hr className="at-most-medium"/>*/}
                        {rightMenu.map((menuSection, index) => (
                            <Fragment key={index}>
                                {menuSection.map(({active, as, badge, badgeColor, icon, title, ...otherProps}, index) => (
                                    <NavItem key={index} className="at-most-medium">
                                        <NavLink tag={as as any}
                                                 key={index}
                                                 className={`secondary${icon != null ? ' icon' : ''}`}
                                                 {...otherProps}>
                                            {icon != null && isString(icon) && <AWIcon name={icon as AWIconName}/>}
                                            {icon != null && !isString(icon) && icon}
                                            <span className="title">{title}</span>
                                            { badge && <Badge color={badgeColor ? badgeColor : 'warning'}>{badge}</Badge>}
                                        </NavLink>
                                    </NavItem>
                                ))}
                                {index < menuSection.length - 1 && <DropdownItem divider/>}
                            </Fragment>
                        ))}
                    </Fragment>
                    }
                </Nav>
            </Collapse>
            }
            { rightNav }
            { !rightNav && rightMenu && (
            <Nav className="at-least-medium right"
                 navbar>
                <UncontrolledDropdown nav
                                      inNavbar>
                    <DropdownToggle nav caret>
                        {rightTrigger}
                    </DropdownToggle>
                    {rightMenu && (
                    <DropdownMenu right>
                        {rightMenu.map((menuSection, index) => (
                            <Fragment key={index}>
                                {menuSection.map(({active, as, badge, badgeColor, icon, title, ...otherProps}, index) => (
                                    <DropdownItem tag={as as any}
                                                  key={index}
                                                  className={ icon != null ? "icon" : undefined}
                                                  {...otherProps}>
                                        {icon != null && isString(icon) && <AWIcon name={icon as AWIconName}/>}
                                        {icon != null && !isString(icon) && icon}
                                        {title}
                                        { badge && <Badge color={badgeColor ? badgeColor : 'warning'}>{badge}</Badge>}
                                    </DropdownItem>
                                ))}
                                {index < menuSection.length && <DropdownItem divider/>}
                            </Fragment>
                        ))}
                    </DropdownMenu>
                    )}
                </UncontrolledDropdown>
            </Nav>
            )}
        </Navbar>
    );
};

export default AWNavBar;