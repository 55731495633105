const PROFILE_NAME_REGEXP = /^[a-zA-Z0-9_]{1,32}$/;

export const isProfileNameValid = (profileName: string) => {
  return PROFILE_NAME_REGEXP.test(profileName);
};

export const isPasswordValid = (input: string): boolean => {
  const regex = /^.*(?=.{8,})((?=.*[A-Z])(?=.*\d)(?=.*\W)|(?=.*[a-z])(?=.*\d)(?=.*\W)|(?=.*[a-z])(?=.*[A-Z])(?=.*\W)|(?=.*[a-z])(?=.*[A-Z])(?=.*\d)).*$/;
  return regex.test(input);
};

export const isTunIPValid = (input: string): boolean => {
  const regex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-4])\.){2}0.0$/;
  return regex.test(input);
};

export const isIPValid = (input: string): boolean => {
  const regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return regex.test(input);
};

export const isAESKeyValid = (input: string): boolean => {
  const regex = /^[a-z0-9A-Z]*$/;
  return regex.test(input);
};

export const isURLValid = (input: string): boolean => {
  const regex = /^[-a-zA-Z0-9@:%._+~#=/]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
  return regex.test(input);
};

export const isEmailValid = (input: string): boolean => {
  //eslint-disable-next-line
  const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi;
  return regex.test(input);
}

export const isPasswordAuth0Compliant = (input: string): boolean => {
  //eslint-disable-next-line
  const regex = /(?=^.{8,128}$)((?=.*[a-z])(?=.*[A-Z])(?=.*\d)).*/;
  // auth0 "Fair" policy : length 8 to 128, at least 1 lowercase, 1 uppercase and 1 number 
  return regex.test(input);
}