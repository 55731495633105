import { FunctionComponent } from "react";
import React from "react";

interface AWLoaderProps {
    active: boolean,
    position: 'inline' | 'centered',
    size?: string
}

const AWLoader: FunctionComponent<AWLoaderProps> = ({ active, position, size }) => {
    if(!active) return null;
    return (
        <div className={`aw-loader ${position}`} aria-live="polite" aria-busy="true">
            <div className={`scale-loader ${size}`}>
                <span className="bar1"/>
                <span className="bar2"/>
                <span className="bar3"/>
                <span className="bar4"/>
                <span className="bar5"/>
            </div>
        </div>
    );
};

export default AWLoader;