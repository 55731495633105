// Hook
import { useEffect } from "react"

type KeyboardEventHandlerType = (e: KeyboardEvent) => void;

type HookPropTypes = {
    targetKeys: Array<string|number>;
    handler: (pressed: boolean) => void;
}

export function useKeyPress(params: HookPropTypes) {
    // State for keeping track of whether key is pressed
    const { targetKeys, handler } = params;

    // If pressed key is our target key then set to true
    const downHandler: KeyboardEventHandlerType = ({ key }) => {
        if (targetKeys.indexOf(key) !== -1) {
            if(handler){
                handler(true);
            }
        }
    }

    // If released key is our target key then set to false
    const upHandler: KeyboardEventHandlerType = ({ key }) => {
        if (targetKeys.indexOf(key) !== -1) {
            if(handler){
                handler(false);
            }
        }
    }

    const addListeners = () => {
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);
    }

    const removeListeners = () => {
        window.removeEventListener('keydown', downHandler);
        window.removeEventListener('keyup', upHandler);
    }

    // Add event listeners
    useEffect(() => {
        addListeners();
        // Remove event listeners on cleanup
        return () => {
            removeListeners();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handler]); // Empty array ensures that effect is only run on mount and unmount

    return;
}