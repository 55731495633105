import React, { FunctionComponent, ReactNode } from 'react';

interface AWCircularBadgeProps {
  children?: ReactNode,
  color?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info'
}

const AWCircularBadge: FunctionComponent<AWCircularBadgeProps> = ({ children, color = 'secondary' }) => (
  <div className={`aw-circular-badge ${color}`}>
    { children != null &&
    <div className="text-container">
        <div className="text">{children}</div>
    </div>
    }
  </div>
);

export default AWCircularBadge;