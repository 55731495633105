import { Field, FieldProps } from "formik";
import React, { Fragment, FunctionComponent, useState } from "react";
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  UncontrolledTooltip
} from 'reactstrap';
import { AWFormFieldCommonProps, AWFormFieldErrorName } from './forms.types';
import { getErrorText, getText } from './forms.utils';
import AWIcon from '../icon';

interface AWFormFieldPasswordProps extends AWFormFieldCommonProps {
  previewEnabled?: boolean;
}

const AWFormFieldPassword: FunctionComponent<AWFormFieldPasswordProps> = ({
                                                                        componentProps = {},
                                                                        errorTexts,
                                                                        fieldTexts,
                                                                        hintPosition = 'bottom',
                                                                        previewEnabled = false,
                                                                        wrapperProps = {},
                                                                        ...fieldProps
                                                                      }) => {
  const [isPasswordClear, setIsPasswordClear] = useState(false);
  const { value: componentValue, type, ...otherComponentProps } = componentProps;
  // Texts
  const label = getText(fieldTexts, fieldProps.name, 'label');
  const placeholder = getText(fieldTexts, fieldProps.name, 'placeholder');
  const hint = getText(fieldTexts, fieldProps.name, 'hint');

  // Computing id
  if(!otherComponentProps.id){
    otherComponentProps.id = fieldProps.name;
  }
  return (
    <Field
      {...fieldProps}>
      {(props: FieldProps) => {
        // Extracting props
        const { field, meta } = props;
        const { value, ...otherFormikFieldProps } = field;

        // Computing value props
        const valueProps: { [x: string]: any } = { value };
        const isInvalid = meta.error != null && meta.touched;

        const input = (
          <Fragment>
            <Input type={ previewEnabled && isPasswordClear ? 'text' : 'password' }
                   disabled={fieldProps.disabled}
                   placeholder={placeholder} // Placeholder by default
                   invalid={isInvalid} // Used for displaying error
                   {...otherComponentProps} // Spreading component props, except 'options' and 'value' props
                   {...otherFormikFieldProps} // Spreading all Formik field props: onChange, onBlur, etc...
                   {...valueProps } />
            { hint && hintPosition === 'icon' &&
            <Fragment>
                <AWIcon className={`form-hint${previewEnabled ? ' with-reveal-button' : ''}`}
                        id={`${otherComponentProps.id}_hint`}
                        name="info_circle"/>
                <UncontrolledTooltip aria-describedby={`${otherComponentProps.id}_hint`}
                                     placement="top"
                                     target={`${otherComponentProps.id}_hint`}
                                     trigger="hover">
                  {() => (
                    <div>{ hint }</div>
                  )}
                </UncontrolledTooltip>
            </Fragment>
            }
          </Fragment>
        );

        return (
          <FormGroup { ...wrapperProps }>
            {label &&
            <Label for={otherComponentProps.id}>
              {label}
            </Label>
            }
            {previewEnabled &&
            <InputGroup className={ isInvalid ? 'is-invalid' : undefined }>
              {input}
              {previewEnabled &&
              <InputGroupAddon addonType="append">
                  <Button className="no-top-padding no-bottom-padding"
                          color="secondary"
                          onClick={() => setIsPasswordClear(!isPasswordClear)}>
                      <AWIcon name={isPasswordClear ? 'visibility_off' : 'visibility'}/>
                  </Button>
              </InputGroupAddon>
              }
            </InputGroup>
            }
            {!previewEnabled && input}
            <FormFeedback>
              { getErrorText(errorTexts, meta.error as AWFormFieldErrorName) }
            </FormFeedback>
            { hint && hintPosition === 'bottom' &&
            <div className="indicator" aria-describedby={`${otherComponentProps.id}_hint`}>
              { hint }
            </div>
            }
          </FormGroup>
        );
      }}
    </Field>
  );
}

export default AWFormFieldPassword;