import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { AWBitrateDataPoint, AWBitrateGraphProps, AWDefaultGraphColors } from './index';
import AutoSizer from "react-virtualized-auto-sizer";
import {
  DiscreteColorLegend,
  HorizontalGridLines,
  LineSeries,
  VerticalBarSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis
} from 'react-vis';
import Moment from 'moment';

const height = 125;
const tickTotalX = 4;
const tickTotalY = 4;
const tickWidth = 0;

const AWBitrateGraphVis: FunctionComponent<AWBitrateGraphProps> = ({ axis,
                                                                     data,
                                                                     linkInterfaceNames,
                                                                     legend,
                                                                     minWidth,
                                                                     margins,
                                                                     style,
                                                                     audio= false,
                                                                     audioDrops= false,
                                                                     video= false,
                                                                     videoDrops= false,
                                                                     mpegtsUp = false,
                                                                     linkRx = false,
                                                                     linkRxDrops = false,
                                                                     linkTx = false,
                                                                     linkTxDrops = false,
                                                                   }) => {

  const finalStyleOption = useMemo(() => {
    return { ...AWDefaultGraphColors, ...style };
  }, [style]);

  const getTimestamp = useCallback((d: AWBitrateDataPoint) => {
    return d.timestamp;
  }, []);

  const formatBitrate = useCallback((v) => {
    return `${v}b`;
  }, []);

  const formatTime = useCallback((v) => {
    return Moment(v).format('HH:mm');
  }, []);

  const getAudioBitrate = useCallback((d: AWBitrateDataPoint) => {
    return d.audio && d.audio[0] ? d.audio[0].rx_bitrate : 0;
  }, []);

  const getAudioDroppedPackets = useCallback((d: AWBitrateDataPoint) => {
    return d.audio && d.audio[0] ? d.audio[0].rx_dropped_packets : 0
  }, []);

  const getVideoBitrate = useCallback((d: AWBitrateDataPoint) => {
    return d.video && d.video[0] ? d.video[0].rx_bitrate : 0;
  }, []);

  const getVideoDroppedPackets = useCallback((d: AWBitrateDataPoint) => {
    return d.video && d.video[0] ? d.video[0].rx_dropped_packets : 0
  }, []);

  const maxLostPackets = useMemo(() => {
    if(data.length === 0) return 1;
    const lastDataPoint = data[data.length - 1];
    return lastDataPoint ? Math.max(
      lastDataPoint.audio && lastDataPoint.audio[0] ? lastDataPoint.audio[0].tot_rx_lost_packets : 0,
      lastDataPoint.video && lastDataPoint.video[0] ? lastDataPoint.video[0].tot_rx_lost_packets : 0
    ) : 1;
  }, [data]);

  const legendItems = useMemo(() => {
    const labels: { title: string; color: string; strokeWidth: number; }[] = [];
    const strokeWidth = 1;
    if(audio){
      labels.push({
        title: legend && legend.audioLabel ? legend.audioLabel : '',
        color: finalStyleOption.audioBitrateColor,
        strokeWidth
      });
    }
    return labels;
  }, [audio, legend, finalStyleOption]);

  return (
    <AutoSizer disableHeight>
      {({ width }) => {
        return (!minWidth || width > minWidth) ?
          (
            <div className="graph">
              <div className="base">
                <XYPlot
                  getX={getTimestamp}
                  getY={getVideoBitrate}
                  xType="time"
                  height={height}
                  width={width}
                  margin={margins}>
                  <HorizontalGridLines tickTotal={tickTotalY} style={{ stroke: finalStyleOption.gridColor }} />
                  <VerticalGridLines tickTotal={tickTotalX} style={{ stroke: finalStyleOption.gridColor }} />
                  <XAxis tickTotal={tickTotalX} tickFormat={formatTime} tickSizeOuter={0} tickSizeInner={tickWidth} style={{ stroke: finalStyleOption.gridColor, text: { color: finalStyleOption.textColor } }} />
                  <YAxis tickTotal={tickTotalY} tickFormat={formatBitrate} tickSizeOuter={0} tickSizeInner={tickWidth} style={{ stroke: finalStyleOption.gridColor, text: { color: finalStyleOption.textColor } }} />
                  <LineSeries getNull={(d) => d.hidden !== true} data={data as any} color={finalStyleOption.videoBitrateColor} />
                </XYPlot>
              </div>
              <div className="overlay">
                <XYPlot
                  getX={getTimestamp}
                  getY={getAudioBitrate}
                  xType="time"
                  height={height}
                  width={width}
                  margin={margins}>
                  <LineSeries getNull={(d) => d.hidden !== true} data={data as any} color={finalStyleOption.audioBitrateColor} />
                </XYPlot>
              </div>
              { audioDrops && (
                <div className="overlay">
                  <XYPlot
                    getX={getTimestamp}
                    getY={getAudioDroppedPackets}
                    height={height}
                    width={width}
                    margin={margins}>
                    <YAxis orientation="right" tickSizeOuter={0} tickSizeInner={tickWidth}/>
                    <VerticalBarSeries data={data as any} color={finalStyleOption.audioDroppedPacketsColor} barWidth={1}/>
                  </XYPlot>
                </div>
              )}
              { videoDrops && (
                <div className="overlay">
                  <XYPlot
                    getX={getTimestamp}
                    getY={getVideoDroppedPackets}
                    yDomain={[0, maxLostPackets]}
                    height={height}
                    width={width}
                    margin={margins}>
                    <YAxis orientation="right" tickSizeOuter={0} tickSizeInner={tickWidth}/>
                    <VerticalBarSeries data={data as any} color={finalStyleOption.videoDroppedPacketsColor} barWidth={1}/>
                  </XYPlot>
                </div>
              )}
              { legend && (
                <div className="overlay">
                  <DiscreteColorLegend
                    width={width}
                    orientation="horizontal"
                    items={legendItems}
                  />
                </div>
              )}
            </div>
          ) : null;
      }}
        </AutoSizer>
        );
      }

export default AWBitrateGraphVis;