import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
} from "react";
import { createPortal } from "react-dom";
import { Button, Col, Container, Row } from "reactstrap";

import { isString } from "../../utils/string-utils";
import AWIcon, { AWIconName } from "../icon";
import { useKeyPress } from "../../hooks/use-key-press";

interface AWModalProps {
  children: ReactNode;
  confirm?: boolean;
  icon?: AWIconName | ReactNode;
  onClose?: () => void;
  open: boolean;
  subtitle?: string | ReactNode;
  title: string | ReactNode;
  widescreen?: boolean;
}

const AWModal: FunctionComponent<AWModalProps> = ({
  children,
  confirm = false,
  icon = null,
  onClose,
  open,
  subtitle = null,
  title,
  widescreen,
}) => {
  const escapeHandler = useCallback(
    (pressed: boolean) => {
      if (open && onClose) {
        onClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open]
  );

  useKeyPress({
    targetKeys: ["Esc", "Escape", 27],
    handler: escapeHandler,
  });

  useEffect(() => {
    if (open) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [open]);

  const renderModal = () => {
    if (!open) {
      return null;
    }
    return (
      <div className={`aw-modal${confirm ? " confirm" : ""}`}>
        {!confirm && (
          <Container className="header" fluid={widescreen}>
            <Row className="align-items-center">
              <Col className="aw-modal-title">
                {icon !== null && isString(icon) && (
                  <AWIcon name={icon as any} />
                )}
                {icon !== null && !isString(icon) && icon}
                <div className="content">
                  {title}
                  <div className="subtitle">{subtitle}</div>
                </div>
              </Col>
              <Col xs={1}>
                {onClose && (
                  <Button className="basic close" onClick={() => onClose()}>
                    <AWIcon name="close" />
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        )}

        {confirm && onClose && (
          <Button className="basic close" onClick={() => onClose()}>
            <AWIcon name="close" />
          </Button>
        )}

        <div className="aw-modal-content">
          {confirm && (
            <div className="aw-modal-title">
              {icon !== null && isString(icon) && <AWIcon name={icon as any} />}
              {icon !== null && !isString(icon) && icon}
              <div className="content">
                {title}
                {subtitle !== null && (
                  <div className="subtitle">{subtitle}</div>
                )}
              </div>
            </div>
          )}
          <Container fluid={widescreen}>{children}</Container>
        </div>
      </div>
    );
  };

  return createPortal(
    renderModal(),
    document.getElementById("root") as Element
  );
};

export default AWModal;
