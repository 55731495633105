import React, { FunctionComponent, ReactNode } from "react";

interface AWBadgeLabelProps {
  left?: ReactNode;
  right?: ReactNode;
  fill?: boolean;
  offline?: boolean;
  disabled?: boolean;
  color?: "white" | "red";
  [x: string]: any;
}

const AWBadgeLabel: FunctionComponent<AWBadgeLabelProps> = ({
  left,
  right,
  fill,
  offline,
  disabled,
  children,
  color = "white",
  className,
  ...otherProps
}) => {
  return (
    <span
      {...otherProps}
      className={`badge-label text-clip${offline ? " offline" : ""}${
        disabled ? " disabled" : ""
      } ${color}${className ? " " + className : ""}`}
    >
      {left && <span className="left">{left}</span>}
      {children && (
        <span className={`status text-clip${fill ? " fill" : ""}`}>
          {children}
        </span>
      )}
      {right && <span className="right">{right}</span>}
    </span>
  );
};

export default AWBadgeLabel;
