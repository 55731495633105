import {Input, InputProps} from "reactstrap"
import DayPickerInput from "react-day-picker/DayPickerInput"
import React, {FunctionComponent} from "react"
import Moment from "moment"
import MomentLocaleUtils from "react-day-picker/moment"

import "moment/locale/fr"
// import "react-day-picker/lib/style.css"
import {isString} from "../../utils/string-utils"

interface AWDateInputProps {
    format?: string,
    inputProps?: InputProps,
    locale?: string,
    onChange: (date: Date | undefined, formattedDate: string) => void,
    overlayComponent?: any,
    position?: 'left' | 'right',
    value: string | Date | undefined,
    minDate?: string | Date,
    maxDate?: string | Date
}

const AWDateInput: FunctionComponent<AWDateInputProps> = ({
                                                              format: customFormat = null,
                                                              inputProps,
                                                              locale: customLocale = null,
                                                              onChange,
                                                              overlayComponent,
                                                              position = 'left',
                                                              value,
                                                              minDate,
                                                              maxDate
                                                          }) => {
    const format = customFormat ? customFormat : Moment.localeData(customLocale ? customLocale : 'en').longDateFormat('L');
    if(isString(value)){
        const moment = Moment(value, format);
        if(moment.isValid()){
            value = moment.toDate();
        }
    }

    if(isString(minDate)){
        const moment = Moment(minDate, format);
        if(moment.isValid()){
            minDate = moment.toDate();
        }
    }

    if(isString(maxDate)){
        const moment = Moment(maxDate, format);
        if(moment.isValid()){
            maxDate = moment.toDate();
        }
    }

    let disabledDays: any = {};
    if(minDate){
        disabledDays.before = Moment(minDate).toDate();
    }
    if (maxDate) {
        disabledDays.after = Moment(maxDate).toDate();
    }

    let placeholder = inputProps?.placeholder;
    if(!placeholder){
        placeholder = format;
    }

    function onChangeDay(date: Date) {
        onChange(date, date ? Moment(date).format(format) : '')
    }

    return (
        <DayPickerInput classNames={{
                            overlayWrapper: 'DayPickerInput-OverlayWrapper',
                            container: `DayPickerInput${inputProps?.invalid ? ' is-invalid' : ''}`,
                            overlay: `DayPickerInput-Overlay ${position}`,
                            
                        }}
                        component={Input}
                        dayPickerProps={{
                            locale: customLocale ? customLocale : undefined,
                            localeUtils: MomentLocaleUtils,
                            showOutsideDays: true,
                            selectedDays: Moment(value).toDate(),
                            disabledDays: disabledDays
                        }}
                        format={customFormat ? customFormat : undefined }
                        formatDate={MomentLocaleUtils.formatDate}
                        inputProps={inputProps}
                        keepFocus={overlayComponent == null}
                        onDayChange={onChangeDay}
                        overlayComponent={ overlayComponent }
                        parseDate={MomentLocaleUtils.parseDate}
                        placeholder={placeholder}
                        value={value}/>
    )
}

export default AWDateInput