import React, {FunctionComponent, ReactNode} from 'react'
import {
    Pagination,
    PaginationItem,
    PaginationLink,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap'
import AWIcon from "../icon"

interface AWPaginationProps {
    currentPage: number
    hasMore?: boolean // Only used when pagesTotal is not defined
    hideOnSinglePage?: boolean
    itemsPerPage?: number
    itemsPerPageValues?: number[]
    itemsPerPagePosition?: 'prepend' | 'append'
    pagesTotal?: number
    onChangePage(page: number, itemsPerPage?: number): void
    [x: string]: any
}

const AWPageExplorer: FunctionComponent<AWPaginationProps> = ({ className, currentPage = 1, hasMore= false, hideOnSinglePage = false, itemsPerPage, itemsPerPagePosition = 'append', itemsPerPageValues = [5, 10, 25, 50], pagesTotal, onChangePage, ...otherProps }) => {
    let changesPages = [] as ((event: React.MouseEvent) => void)[]
    let startNumber: number | null = null
    let endNumber: number | null = null

    /**
     * Compute all page number visible
     * 4 numbers always visible
     * Current page placed in second position except when it runs out of numbers (example: first and last page)
     */
    if(pagesTotal){
        if (pagesTotal <= 4) {
            startNumber = 1
            endNumber = pagesTotal
        } else if (currentPage === 1) {
            startNumber = currentPage
            endNumber = currentPage + 3
        } else if (currentPage + 2 > pagesTotal) {
            startNumber = pagesTotal - 3
            endNumber = pagesTotal
        } else {
            startNumber = currentPage - 1
            endNumber = currentPage + 2
        }
    }

    function previous(){
        onChangePage(currentPage - 1, itemsPerPage)
    }

    function next(){
        onChangePage(currentPage + 1, itemsPerPage)
    }

    function first() {
        onChangePage(1, itemsPerPage)
    }

    function last() {
        pagesTotal ? onChangePage(pagesTotal, itemsPerPage) : onChangePage(currentPage + 1)
    }

    function changePage(number: number): (event: React.MouseEvent) => void {
        if (!changesPages[number]) {
            changesPages[number] = () => onChangePage(number, itemsPerPage)
        }
        return changesPages[number]
    }

    let items: ReactNode[] = []
    if(startNumber != null && endNumber != null){
        for (let i = startNumber; i <= endNumber; i++) {
            items.push(
                <PaginationItem active={i === currentPage} key={i}>
                    <PaginationLink onClick={changePage(i)} tag={Button}>{i}</PaginationLink>
                </PaginationItem>
            )
        }
    }

    if(pagesTotal != null && pagesTotal <= 1 && hideOnSinglePage) return null;
    if(pagesTotal == null && !hasMore && currentPage === 1 && hideOnSinglePage) return null;
    const itemsPerPageSelector = (
        <UncontrolledDropdown>
            <DropdownToggle caret>
                {itemsPerPage}
            </DropdownToggle>
            <DropdownMenu>
                { itemsPerPageValues.map(value => (
                    <DropdownItem key={value} onClick={() => onChangePage(currentPage, value)} data-value={value}>{value}</DropdownItem>
                ))}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
    return (
        <div className={`aw-page-explorer${className ? ' ' + className : ''}`} { ...otherProps}>
            { itemsPerPage && itemsPerPagePosition === 'prepend' && itemsPerPageSelector }
            <Pagination>
                <PaginationItem>
                    { pagesTotal != null && <PaginationLink first onClick={first} tag={Button} disabled={currentPage <= 1} /> }
                    { pagesTotal == null && <PaginationLink onClick={previous} tag={Button} disabled={currentPage <= 1}><AWIcon name="chevron_left"/></PaginationLink>}
                </PaginationItem>
                {items}
                <PaginationItem>
                    { pagesTotal != null && <PaginationLink last onClick={last} tag={Button} disabled={currentPage === pagesTotal} /> }
                    { pagesTotal == null && <PaginationLink onClick={next} tag={Button} disabled={!hasMore}><AWIcon name="chevron_right"/></PaginationLink>}
                </PaginationItem>
            </Pagination>
            { itemsPerPage && itemsPerPagePosition === 'append' && itemsPerPageSelector }
        </div>
    )
}

export default AWPageExplorer