import React, {FunctionComponent, useRef} from 'react'
import { Label, Input, InputProps } from 'reactstrap'
import {randomString} from "../../utils/string-utils"

interface AWSwitchProps extends InputProps {}

const AWSwitch: FunctionComponent<AWSwitchProps> = ({ id, ...otherProps}) => {
    const finalId = useRef(randomString(6))
    return (
        <div className="switch">
            <Input id={finalId.current}
                   bsSize="sm"
                   className="switch-checkbox"
                   type="checkbox"
                   {...otherProps}/>
            <Label className="switch-label" for={finalId.current} />
        </div>
    )
}

export default AWSwitch