import React, { FunctionComponent } from 'react';
import AWBitrateGraphRecharts from './recharts';
import AWBitrateGraphVis from './vis';
import { Margin } from 'recharts';

export const GRAPH_TOTAL = 'TOTAL';

export const getGraphRandomColor = (): string => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const AWDefaultGraphColors: AWGraphStyle = {
  textFontSize: 10,
  textColor: '#777777',
  gridColor: '#12121a',
  audioBitrateColor: '#8cc7e0',
  videoBitrateColor: '#ec502e',
  mpegtsUpBitrateColor: '#7dec2e',
  audioDroppedPacketsColor: '#2da432',
  videoDroppedPacketsColor: '#2d83a6',
  linkRxBitrateColors: {
    [GRAPH_TOTAL]: '#ece32e',
  },
  linkTxBitrateColors: {
    [GRAPH_TOTAL]: '#3bec2e',
  },
  linkRxDroppedPacketsColors: {
    [GRAPH_TOTAL]: '#2eecb0'
  },
  linkTxDroppedPacketsColors: {
    [GRAPH_TOTAL]: '#2e57ec'
  }
};

export interface AWGraphAxis {
  bitrateLabel: string;
  droppedPacketsLabel: string;
}

export interface AWGraphLegend {
  layout: 'horizontal' | 'vertical';
  align: 'left' | 'center' | 'right';
  verticalAlign: 'top' | 'middle' | 'bottom';
  audioLabel: string;
  videoLabel: string;
  mpegtsUpLabel: string;
  audioDroppedPacketsLabel: string;
  videoDroppedPacketsLabel: string;
  linkRxBitrateTotalLabel: string;
  linkRxBitrateFormatLabel: (linkName: string) => string;
  linkRxDroppedPacketsTotalLabel: string;
  linkRxDroppedPacketsFormatLabel: (linkName: string) => string;
  linkTxBitrateTotalLabel: string;
  linkTxDroppedPacketsTotalLabel: string;
}

export interface AWGraphStyle {
  textFontSize: number;
  textColor: string;
  gridColor: string;
  audioBitrateColor: string;
  videoBitrateColor: string;
  mpegtsUpBitrateColor: string;
  audioDroppedPacketsColor: string;
  videoDroppedPacketsColor: string;
  linkRxBitrateColors: {
    TOTAL: string;
    [interfaceName: string]: string;
  };
  linkTxBitrateColors: {
    TOTAL: string;
    [interfaceName: string]: string;
  };
  linkRxDroppedPacketsColors: {
    TOTAL: string;
    [interfaceName: string]: string;
  };
  linkTxDroppedPacketsColors: {
    TOTAL: string;
    [interfaceName: string]: string;
  };
}

export interface AWLinkBitrate {
  name: string;
  recv_bytes: number;
  rtt: number;
  rx_bitrate: number;
  rx_lost_nb_packets: number;
  rx_lost_nb_packets_current: number;
  send_bytes: number;
  tx_bitrate: number;
  tx_lost_nb_packets: number;
}

export interface AWMediaBitrate {
  cur_rx_lost_packets: number;
  name: string;
  rx_bitrate: number;
  rx_dropped_packets: number;
  tot_rx_lost_packets: number;
  type: 'audio' | 'video' | 'mpegtsUp';
}

export interface AWBitrateDataPoint {
  timestamp: number;
  audio?: AWMediaBitrate[];
  mpegtsUp?: AWMediaBitrate[]; // Video return
  video?: AWMediaBitrate[];
  link?: AWLinkBitrate[];
}

export interface AWBitrateGraphProps {
  data: AWBitrateDataPoint[];
  audio?: boolean;
  audioDrops?: boolean;
  video?: boolean;
  videoDrops?: boolean;
  linkRx?: boolean;
  linkRxDrops?: boolean;
  linkTx?: boolean;
  linkTxDrops?: boolean;
  mpegtsUp?: boolean; // Video return
  linkInterfaceNames?: (string | typeof GRAPH_TOTAL)[];
  minWidth?: number;
  margins?: Partial<Margin>;
  axis?: Partial<AWGraphAxis>;
  style?: Partial<AWGraphStyle>;
  legend?: Partial<AWGraphLegend>;
}

const AWStreamGraph: FunctionComponent<AWBitrateGraphProps & { type?: 'vis' | 'recharts' }> = ({ type = 'recharts', ...otherProps }) => {
  if(type === 'recharts'){
    return (
      <AWBitrateGraphRecharts {...otherProps} />
    );
  }
  if(type === 'vis'){
    return (
      <AWBitrateGraphVis {...otherProps} />
    );
  }
  return null;
}

export default AWStreamGraph;